import { Flex, Box, Table, Checkbox, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Button, toast, useToast, useDisclosure } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import * as React from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	AlertDialogCloseButton,
  } from '@chakra-ui/react'

import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable
} from '@tanstack/react-table';

// Custom components
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import getUsers from '../variables/tableDataCheck';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { setTimeout } from 'timers';
// type RowObj = {
// 	name: [string, boolean];
// 	progress: string;
// 	quantity: number;
// 	date: string;
// 	info: boolean;
// };
type RowObj = {
	id: [string, boolean];
	name: string;
	role: string;
	timeout: string;
	delete: string;
};
 
const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function CheckTable(props: { tableData: any }) {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const toast = useToast();
	const cancelRef = React.useRef()
	const [deleteName, setDeleteName] = React.useState<string>("");
	const { tableData } = props;
	const [ sorting, setSorting ] = React.useState<SortingState>([]);
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	let defaultData = tableData;
	const columns = [
		columnHelper.accessor('id', {
			id: 'id',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					ID
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Checkbox defaultChecked={info.getValue()[1]} colorScheme='brandScheme' me='10px' />
					<Text color={textColor} fontSize='sm' fontWeight='700'>
						{info.getValue()[0]}
					</Text>
				</Flex>
			)
		}),
		columnHelper.accessor('name', {
			id: 'name',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					NAME
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize='sm' fontWeight='700'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
		columnHelper.accessor('role', {
			id: 'role',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					ROLE
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='700'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('timeout', {
			id: 'timeout',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					TIMEOUT
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='700'>
					{info.getValue().split("GMT")[0]}
				</Text>
			)
		}),
		columnHelper.accessor('name', {
			id: 'namex',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					DELETE
				</Text>
			),
			cell: (info) => (
				<Button onClick={() => {
					onOpen()
					setDeleteName(info.getValue())
				}} color="white" bgColor={"red.400"} fontSize='sm' fontWeight='700'>
					<DeleteIcon />
				</Button>
			)
		}),
	];
	const [ data, setData ] = React.useState(() => [ ...defaultData ]);
	const table = useReactTable({
		data,
		columns,
		state: {
			sorting
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		debugTable: true
	});
	const fillterx = (event: any) => {
		if (event.target.value.length === 0) {
			setData(tableData);
		}
		let dataxxx = defaultData.filter((v: any) => {
			let name: string = v.name;
			if (name.includes(event.target.value)) {
				return v
			}
		})
		setData(dataxxx)
	}

	const deletex = () => {
		console.log(deleteName)
		fetch("https://api.wisdomslip.cloud/v1/delete/"+deleteName).then(() => {
			toast({
				title: "ลบสมาชิก "+deleteName+" สำเร็จ",
				description: "",
				status: "success",
				duration: 3000,
				isClosable: true,
         	})
			window.location.reload();
		})
	}
	return (
		<>
			<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
				<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
					<Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
						Check Table
					</Text>
					<SearchBar
						mb={() => {
							return { base: '10px', md: 'unset' };
						}}
						me='10px'
						borderRadius='30px'
						callback={fillterx}
					/>
				</Flex>
				<Box>
					<Table variant='simple' color='gray.500' mb='24px' mt="12px">
						<Thead>
							{table.getHeaderGroups().map((headerGroup) => (
								<Tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => {
										return (
											<Th
												key={header.id}
												colSpan={header.colSpan}
												pe='10px' 
												borderColor={borderColor}
												cursor='pointer'
												onClick={header.column.getToggleSortingHandler()}>
												<Flex
													justifyContent='space-between'
													align='center'
													fontSize={{ sm: '10px', lg: '12px' }}
													color='gray.400'>
													{flexRender(header.column.columnDef.header, header.getContext())}{{
														asc: '',
														desc: '',
													}[header.column.getIsSorted() as string] ?? null}
												</Flex>
											</Th>
										);
									})}
								</Tr>
							))}
						</Thead>
						<Tbody>
							{table.getRowModel().rows.slice(0, 5).map((row) => {
								return (
									<Tr key={row.id}>
										{row.getVisibleCells().map((cell) => {
											return (
												<Td
													key={cell.id}
													fontSize={{ sm: '14px' }}
													minW={{ sm: '150px', md: '200px', lg: 'auto' }}
													borderColor='transparent'>
													{flexRender(cell.column.columnDef.cell, cell.getContext())}
												</Td>
											);
										})}
									</Tr>
								);
							})}
						</Tbody>
					</Table>
				</Box>
			</Card>
			<AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Discard Changes?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are u sure for delete "{deleteName}" ?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button onClick={deletex} colorScheme='red' ml={3}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
		</>
	);
} 