import { Button, Flex, Image, Link, Text, useColorModeValue } from '@chakra-ui/react';
import logoWhite from 'assets/img/layout/logoWhite.png';

export default function SidebarDocs() {
	const bgColor = 'linear-gradient(135deg, #868CFF 0%, #4318FF 100%)';
	const borderColor = useColorModeValue('white', 'navy.800');

	return (
		<></>
	);
}
