type RowObj = {
	id: [string, boolean];
	name: string;
	role: string;
	timeout: string;
};

export interface Root {
	status: string
	data: Daum[]
  }
  
  export interface Daum {
	login: boolean
	_id: string
	username: string
	password: string
	name: string
	roles: string
	createtime: string
	timeout: string
	access_ip: string[]
	__v: number
  }
  

const getUsers = async () => {
	let response = await fetch("https://api.wisdomslip.cloud/v1/getUsers")
	let response_json: Root = await response.json();
	let rows: RowObj[] = [];
	if (response_json.status == "success") {
		response_json.data.forEach(element => {
			// console.log(element);
			rows.push({
				id: [element._id, false],
				name: element.username,
				role: element.roles,
				timeout: element.timeout
			})
		});
	}
	return rows.reverse();
}

export default getUsers;
