// Chakra imports
import { AvatarGroup, Avatar, Box, Button, Flex, Icon,FormControl,Select,Input, FormLabel, Image, Link, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import { useDisclosure } from '@chakra-ui/react'
import Card from 'components/card/Card';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useToast,
  } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom';

// Assets
import * as React from 'react';
import { IoHeart, IoHeartOutline } from 'react-icons/io5';
import { useEffect } from "react";

export default function NFT(props: {
	image: string;
	name: string;
	author: string;
	bidders: string[];
	download: string;
	currentbid: string | number;
}) {
	
	const { image, name, author, bidders, download, currentbid } = props;
	const [ like, setLike ] = React.useState(false);
	const textColor = useColorModeValue('navy.700', 'white');
	const textColorBid = useColorModeValue('brand.500', 'white');
	const toast = useToast();
	let location = useLocation();

	useEffect(()=>{
    
		fetch("https://api.wisdomslip.cloud/v1/me", {
		  headers:{
			Authorization: "Bearer "+ localStorage.getItem("token")
		  }
		}).then((res) => res.json()).then(json => {
		  console.log(json)
		if (json.status !== "success") {
		  window.location.href ="/horizon-ui-chakra-ts#/auth/sign-in"
		}
		});
	  })
	


	const { isOpen, onOpen, onClose } = useDisclosure()

	const initialRef = React.useRef(null)
	const finalRef = React.useRef(null)
	

	function onSubmit() {
		let data = {
			username: (document.getElementById("username") as HTMLInputElement).value,
			timeout: (document.getElementById("timeout") as HTMLInputElement).value
		};
		fetch("https://api.wisdomslip.cloud/v1/addUserTimeout", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				"username" : data.username,
				"timeout" : data.timeout
			})
		}).then(response => response.text())
		.then(text => {
			if (!text.includes("username not found") || !text.includes("Sonething went wrong")) {
				onClose()
				toast({
					title: "เปลี่ยนสำเร็จ",
					description: "",
					status: "success",
					duration: 9000,
					isClosable: true,
				});
			}
            else {
                toast({
					title: "ไม่เจอ username",
					description: "",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
            }
		})
	  }

	return (
		<>
			<Modal
				initialFocusRef={initialRef}
				finalFocusRef={finalRef}
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Add Timeout</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<FormControl>
						<FormLabel>Username</FormLabel>
						<Input id="username" textColor={"white"} placeholder='Username' />
						</FormControl>

						<FormControl mt={4}>
						<FormLabel>Timeout</FormLabel>
						<Input id="timeout" placeholder='timeout' textColor={"white"} type='text' />
						</FormControl>
						
					</ModalBody>
					<ModalFooter>
						<Button colorScheme='blue' onClick={onSubmit} mr={3}>
						Change
						</Button>
						<Button id="closeee" onClick={onClose}>Cancel</Button>
					</ModalFooter>
					</ModalContent>
				</Modal>

			<Card p='20px'>
			<Flex direction={{ base: 'column' }} justify='center'>
				<Box mb={{ base: '20px', '2xl': '20px' }} position='relative'>
					<Image
						src={image}
						w={{ base: '100%', '3xl': '100%' }}
						h={{ base: '100%', '3xl': '100%' }}
						borderRadius='20px'
					/>
					<Button
						position='absolute'
						bg='white'
						_hover={{ bg: 'whiteAlpha.900' }}
						_active={{ bg: 'white' }}
						_focus={{ bg: 'white' }}
						p='0px !important'
						top='14px'
						right='14px'
						borderRadius='50%'
						minW='36px'
						h='36px'
						onClick={() => {
							setLike(!like);
						}}>
						<Icon
							transition='0.2s linear'
							w='20px'
							h='20px'
							as={like ? IoHeart : IoHeartOutline}
							color='brand.500'
						/>
					</Button>
				</Box>
				<Flex flexDirection='column' justify='space-between' h='100%'>
					<Flex
						justify='space-between'
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row'
						}}
						mb='auto'>
						<Flex direction='column'>
							<Text
								color={textColor}
								fontSize={{
									base: 'xl',
									md: 'lg',
									lg: 'lg',
									xl: 'lg',
									'2xl': 'md',
									'3xl': 'lg'
								}}
								mb='5px'
								fontWeight='bold'
								me='14px'>
								{name}
							</Text>
							<Text
								color='secondaryGray.600'
								fontSize={{
									base: 'sm'
								}}
								fontWeight='400'
								me='14px'>
								{author}
							</Text>
						</Flex>
						<AvatarGroup
							max={3}
							color={textColorBid}
							size='sm'
							mt={{
								base: '0px',
								md: '10px',
								lg: '0px',
								xl: '10px',
								'2xl': '0px'
							}}
							fontSize='12px'>
							{bidders.map((avt, key) => <Avatar key={key} src={avt} />)}
						</AvatarGroup>
					</Flex>
					<Flex
						align={{
							base: 'center',
							md: 'start',
							lg: 'center',
							xl: 'start',
							'2xl': 'center'
						}}
						justify='space-between'
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row'
						}}
						mt='25px'>
						<Text fontWeight='700' fontSize='sm' color={textColorBid}>
							
						</Text>
						<Link
							mt={{
								base: '0px',
								md: '10px',
								lg: '0px',
								xl: '10px',
								'2xl': '0px'
							}}>
							<Button
								variant='darkBrand'
								color='white'
								fontSize='sm'
								fontWeight='500'
								borderRadius='70px'
								px='24px'
								py='5px'
								onClick={onOpen}
								>
								{currentbid}
							</Button>
						</Link>
					</Flex>
				</Flex>
			</Flex>
		</Card>
		</>
	);
}
